<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col-reverse lg:flex-row-reverse lg:gap-x-8 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='flex flex-col justify-between items-start lg:items-end'>
        <div>
          <img :src='eventConfigLogoImageUrl'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        </div>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div class='text-sm'>
        <div>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/kda2022/kda2022_kakaochat_image.png'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='kakaoChannelChat'/>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start text-left w-full items-start'>
          © 2022 Korean Diabetes Association
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Kda2022LayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    kakaoChannelChat () {
      window.open('http://pf.kakao.com/_hVMYb', '_blank', 'width=350,height=550');
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
